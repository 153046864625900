<template>
<div class="admin-container" v-loading.fullscreen.lock="loading">
    <div class="admin-container-header">
        <div class="flex justify-between">
            <h1> Faq </h1>
            <div class="flex items-center">
                <el-input placeholder="Название раздела" v-model="search"> </el-input>
                <button class="button ml-10" @click="$router.push('/admin/faq/add')"> Создать</button>
            </div>
        </div>
    </div>
    <div class="admin-table">
        <el-table :data="FaqList" style="width: 100%;" default-expand-all>
            <el-table-column label="Назва" class="table-pl-20px" >
                <template slot-scope="scope">
                    <p class="title-w500"> {{  scope.row.question_ua }} </p>
                </template>
            </el-table-column>
            <el-table-column label="Дії" width="180" class="table-pr-20px ">
                <template slot-scope="scope">
                    <div class="flex">
                        <el-button @click="$router.push(`/admin/faq/edit/${scope.row.id}`)" slot="reference">Edit</el-button>
                        <el-popconfirm class="ml-10" @confirm="_DELETE(scope.row.id)" confirm-button-text='OK' cancel-button-text='No, Thanks' icon="el-icon-info" icon-color="red" title="Are you sure to delete this?">
                            <el-button class="delete" slot="reference">Delete</el-button>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div class="admin-poginations">
        <el-pagination :page-sizes="[10, 20, 50, 100]" :page-size="page_size" @current-change="paginateSize" @size-change="paginateSize" :current-page.sync="current_page" :hide-on-single-page="false" background layout="sizes, prev, pager, next" :page-count="page_count">
        </el-pagination>
    </div> 
</div>
</template>

<script>
export default {
    data() {
        return {
            current_page: 1,
            page_count: 1,
            page_size: 10,
            loading: false,
            FaqList: [], 
            search:'',
        }

    },
    methods: {
        Get() {
            this.loading = true
            this.axios.get('/admin/faq').then(res => {
                    this.FaqList = res.data.data.data
                    this.page_count = parseInt(res.data.data.total / this.page_size) 
                    if (res.data.data.total % this.page_size > 0) {
                        this.page_count = this.page_count + 1
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        _DELETE(i) {
            this.loading = true
            this.axios.delete(`/admin/faq/${i}`)
                .then(() => {
                     this.$notify({
                                title: 'Delete',
                                message: 'Ви успішно ввійшли',
                                type: 'success'
                            });
                    this.Get()
                })
                .catch(error => { 
                            let ERRORS = error.response.data.message;
                            let keys = Object.keys(ERRORS);
                            let error_msg = ERRORS[keys[0]];
                            this.$notify.error({
                                 title: 'Произошла ошибка',
                                message: error_msg ? error_msg : "Произошла ошибка",
                            });

                        })
                .finally(() => {
                    this.loading = false
                })
        },
        paginateSize() {
            console.log("AAA")
        }
    },
    mounted() {
        this.Get()
    }
}
</script>

<style>
.admin-container {
    background: var(--white-bg);
    min-height: 100%;
    width: 100%;

}

.admin-container-header {
    padding: 20px;
}

.admin-table {
    width: 100%;
}

.admin-poginations {
    padding: 20px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
}
</style>
